var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"valProvider",attrs:{"rules":{required: _vm.isRequired},"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"af-custom-label mb-4 is-flex is-align-items-center is-justify-content-space-between"},[_vm._v(" "+_vm._s(_vm.field.label)+" "),(errors[0])?_c('b-icon',{staticClass:"ml-2",attrs:{"icon-pack":"fa","icon":"exclamation-circle","type":"is-danger","size":"is-small"}}):_vm._e()],1),_c('div',{staticClass:"question-container",class:{
        'question-container--two-columns': _vm.field.inputValues.length <= 2,
        'single-column': _vm.getMaxValueLength() > 64,
      }},_vm._l((_vm.field.inputValues),function(inputValue,index){return _c('div',{key:_vm.field.identifier + index,attrs:{"id":("input_" + (_vm.field.identifier) + "_" + index)},on:{"click":function($event){_vm.toggleSelection(inputValue) || validate(_vm.selectedValues)}}},[(inputValue.labelIcon)?_c('div',{staticClass:"answer-tile cursor-pointer",class:{
            'answer-tile--active': _vm.selectedValues.includes(inputValue.value),
          }},[_c('b-icon',{staticClass:"mb-1",attrs:{"icon-pack":"fa","icon":inputValue.labelIcon,"type":"is-grey-icon","size":"is-medium"}}),_c('br'),_c('span',[_vm._v(_vm._s(inputValue.text))])],1):_c('div',{staticClass:"answer-tile cursor-pointer",class:{
            'answer-tile--active': _vm.selectedValues.includes(inputValue.value),
          }},[_c('span',[_vm._v(_vm._s(inputValue.text))])])])}),0),(errors[0])?_c('div',{staticClass:"field-error-message"},[_c('p',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }