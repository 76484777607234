<template>
  <validation-provider ref="valProvider" :rules="{required: isRequired}" v-slot="{ validate, errors }" slim>
    <div class="mb-6">
      <label class="af-custom-label mb-4 is-flex is-align-items-center is-justify-content-space-between">
        {{ field.label }}
        <b-icon
          v-if="errors[0]"
          icon-pack="fa"
          icon="exclamation-circle"
          type="is-danger"
          size="is-small"
          class="ml-2"
        ></b-icon>
      </label>
        
      <div
        class="question-container"
        :class="{
          'question-container--two-columns': field.inputValues.length <= 2,
          'single-column': getMaxValueLength() > 64,
        }"
      >
        <div
          v-for="(inputValue, index) in field.inputValues"
          :id="`input_${field.identifier}_${index}`"
          :key="field.identifier + index"
          @click="toggleSelection(inputValue) || validate(selectedValues)"
        >
          <div
            v-if="inputValue.labelIcon"
            class="answer-tile cursor-pointer"
            :class="{
              'answer-tile--active': selectedValues.includes(inputValue.value),
            }"
          >
            <b-icon
              icon-pack="fa"
              :icon="inputValue.labelIcon"
              type="is-grey-icon"
              size="is-medium"
              class="mb-1"
            ></b-icon>

            <br />

            <span>{{ inputValue.text }}</span>
          </div>

          <div
            v-else
            class="answer-tile cursor-pointer"
            :class="{
              'answer-tile--active': selectedValues.includes(inputValue.value),
            }"
          >
            <span>{{ inputValue.text }}</span>
          </div>
        </div>
      </div>
      <div v-if="errors[0]" class="field-error-message">
        <p>{{ errors[0] }}</p>
      </div>
    </div>
  </validation-provider>
</template>

<script>
export default {
  name: "ApplicationFlowCheckboxQuestion",
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: null,
    },
    isMortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValues: [],
    };
  },
  mounted() {
    let selectedValues;
    if (this.isMortgage) {
      selectedValues = this.$store.getters[
        "mortgageApplication/getFlowQuestionAnswer"
      ](this.field.identifier);
    } else {
      selectedValues = this.$store.getters[
        "loanApplication/getFlowQuestionAnswer"
      ](this.field.identifier);
    }

    if (selectedValues) {
      this.selectedValues = JSON.parse(selectedValues);
      this.$refs.valProvider.validate(this.selectedValues);
    } else {
      this.selectedValues = [];
    }

    this.$watch("selectedValues", this.valueWatcher);
  },
  methods: {
    valueWatcher() {
      if (this.isMortgage) {
        this.$store.dispatch("mortgageApplication/saveDynamicData", {
          key: this.field.identifier,
          value: this.selectedValue,
        });
      } else {
        this.$store.dispatch("loanApplication/saveDynamicData", {
          key: this.field.identifier,
          value: JSON.stringify(this.selectedValues),
        });
      }
      this.$refs.valProvider.validate();
    },
    toggleSelection(inputValue) {
      const index = this.selectedValues.findIndex(
        (val) => val === inputValue.value
      );

      if (index > -1) {
        this.selectedValues.splice(index, 1);
      } else {
        this.selectedValues.push(inputValue.value);
      }
    },
    getMaxValueLength() {
      return this.field.inputValues.reduce(
        (accumulator, currentValue) => {
          if (accumulator < currentValue.text.length) {
            accumulator = currentValue.text.length
          }
          return accumulator
        },
        0
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

$border-color: #dfe6ef;

.question-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  align-items: stretch;
  justify-items: stretch;

  &--two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .answer-tile {
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 0.7rem;
    text-align: center;
    font-size: 0.75rem;
    overflow: hidden;
    word-break: break-word;
    height: 100%;
    width: 100%;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      border: 2px solid $blue !important;
      color: $blue !important;
    }

    .icon {
      font-size: 1.2rem;
      color: inherit !important;
    }
  }

  &.single-column {
    grid-template-columns: 1fr;

    .answer-tile {
      justify-content: start;
      text-align: left;
    }
  }
}

.field-error-message {
  color: #f14668;
  margin-top: 8px;
  font-size: 12px;
  position: absolute;
}

.mbh {
  .answer-tile {
    border: 2px solid white;
    background: white;
    border-radius: 32px;
    font-size: 14px;

    &--active {
      border: 2px solid #0c4fad !important;
      color: #0c4fad !important;
    }
  }
}

@media only screen and (max-width: 792px) {
  .question-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
